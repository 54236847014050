import Vue from 'vue'
import Vuex from 'vuex'
import storeData from './modules/storeData'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({ // 配置持久化本地存储
    paths: ['storeData',] // 配置只持久化存储diy模块数据
  })],
  state: {
  },
  mutations: {
  },

  actions: {
  },
  modules: {
    storeData
  }
})
