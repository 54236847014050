import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

// 引入Element-ui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// 引入axios
import axios from 'axios'
// 导入 NProgress进度条包对应的JS和CSS
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
// 引入全局样式表
import './assets/css/global.css'
// 引入时间格式
import moment from 'moment'

Vue.use(ElementUI)

// 后端接口
axios.defaults.baseURL = 'https://url.a8a8a8.top/'
axios.interceptors.request.use(config => {
  NProgress.start()
  if (window.sessionStorage.getItem('token')) {
    config.headers.Authorization = 'Token ' + window.sessionStorage.getItem('token')
  }
  return config
})
axios.interceptors.response.use(config => {
  NProgress.done()
  return config
})
// 设置axios别名，方便调用
Vue.prototype.$http = axios
Vue.filter('moment', function (value, formatString) {
  formatString = formatString || 'YYYY-MM-DD HH:mm:ss'
  if (value) return moment(value).format(formatString)
})

Vue.config.productionTip = false

// 设置显示meta属性
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = '阿巴阿巴站点'
  }
  next()
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export default axios
