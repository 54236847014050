import axios from '../main.js'

// 获取书册
export async function getUrls (page,belong) {
    if (page==undefined && belong == undefined) {
        const result = await axios.get('url/')
        const res = result.data
        return res
    } else if (belong == undefined) {
        const result = await axios.get('url/?u='+page+'&p=home')
        const res = result.data
        return res
    }
    const result = await axios.get('url/?u='+page+'&b='+belong+'&p=home')
    const res = result.data
    return res
}