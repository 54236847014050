<template>
  <div class="index">
   <transition 
    enter-active-class="animate__animated animate__fadeIn"
    leave-active-class="animate__animated animate__fadeOut">
        <div class="fixedSearch" v-show="showFixedSearch">
            <div class="search-box-fixed" v-if="windowWidth>=800">
                <el-autocomplete
                    clearable
                    popper-class="my-autocomplete"
                    v-model="search"
                    :fetch-suggestions="querySearch"
                    placeholder="搜索"
                    @select="handleSelect">
                    <i class="el-icon-search el-input__icon" slot="prefix" @click="getSearch"></i>
                    <el-button slot="append" icon="el-icon-search" @click="getSearch" style="background-color:#66b1ff;color:#FFFFFF;">搜一下</el-button>
                    <template slot-scope="{ item }">
                        <div class="name">{{ item.value }} <span style="float:right;" @click.stop="onDelClick(item.value)"><i class="el-icon-error"></i></span> </div>
                        <span class="addr" v-if="item.address != ''" >{{ item.address }}</span>
                        
                    </template>
                </el-autocomplete>
            </div>
            <div class="search-box-small-fixed" v-if="windowWidth<800">
                <el-autocomplete
                    clearable
                    popper-class="my-autocomplete"
                    v-model="search"
                    :fetch-suggestions="querySearch"
                    placeholder="搜索"
                    @select="handleSelect">
                    <el-button slot="append" icon="el-icon-search" @click="getSearch" style="background-color:#66b1ff;color:#FFFFFF;"></el-button>
                    <template slot-scope="{ item }">
                        <div class="name">{{ item.value }} <span style="float:right;" @click.stop="onDelClick(item.value)"><i class="el-icon-error"></i></span> </div>
                        <span class="addr" v-if="item.address != ''" >{{ item.address }}</span>
                        
                    </template>
                </el-autocomplete>
            </div>
        </div>
    </transition>
    <div class="color-box"></div>
    <div class="search-box" v-if="windowWidth>=800">
        <el-autocomplete
            clearable
            popper-class="my-autocomplete"
            v-model="search"
            :fetch-suggestions="querySearch"
            placeholder="搜索"
            @select="handleSelect">
            <i class="el-icon-search el-input__icon" slot="prefix" @click="getSearch"></i>
            <el-button slot="append" icon="el-icon-search" @click="getSearch" style="background-color:#66b1ff;color:#FFFFFF;">搜一下</el-button>
            <template slot-scope="{ item }">
                <div class="name">{{ item.value }} <span style="float:right;" @click.stop="onDelClick(item.value)"><i class="el-icon-error"></i></span> </div>
                <span class="addr" v-if="item.address != ''" >{{ item.address }}</span>
                
            </template>
        </el-autocomplete>
    </div>
    <div class="search-box-small" v-if="windowWidth<800">
        <el-autocomplete
            clearable
            popper-class="my-autocomplete"
            v-model="search"
            :fetch-suggestions="querySearch"
            placeholder="搜索"
            @select="handleSelect">
            <el-button slot="append" icon="el-icon-search" @click="getSearch" style="background-color:#66b1ff;color:#FFFFFF;"></el-button>
            <template slot-scope="{ item }">
                <div class="name">{{ item.value }} <span style="float:right;" @click.stop="onDelClick(item.value)"><i class="el-icon-error"></i></span> </div>
                <span class="addr" v-if="item.address != ''" >{{ item.address }}</span>
                
            </template>
        </el-autocomplete>
    </div>
    <div  class="tag-box">
        <el-tag v-for="(item,index) in types" 
            :key="index"
            style="margin:calc(100vw * 5/ 1920); cursor:pointer;"
            :effect="item.key==type?'dark':'light'"
            @click="changeTag(item.key)"
        >{{item.value}}</el-tag>
    </div>

    <div class="data-box" v-if="windowWidth>=800">
    <div v-for="(items,indexs) in urlsData" :key="indexs">
    <el-divider content-position="left"><i class="el-icon-mobile-phone" v-if="items.data.length>0"></i> {{items.name}}</el-divider>
    <el-row>
    <el-col :span="6" v-for="(item,index) in items.data" :key="index" class="card-box">
    <el-tooltip effect="dark" :content=item.desc placement="bottom" :visible-arrow="false">
    <el-card :shadow="shadowTime" style="cursor:pointer;" :body-style="{ padding: 'calc(100vw * 5/ 1920)' }" body- @click.native="goto(item.thepath)">
        <el-container style="height:calc(100vw *70/ 1920);">
        <el-aside width="120" style="margin:0 !important;padding:calc(100vw *5/ 1920);">
        <img :src= item.logopath class="image" style="height:calc(100vw * 50/ 1920);width:calc(100vw * 50/ 1920);" >
        </el-aside>
        <el-main style="text-align: left !important;padding:0 !important;margin:0 !important;font-size:0px;line-height:calc(100vw * 15/ 1920);">
        <div style="">
        <el-link target="_blank" type="primary" 
        style="color:#4778ab !important;font-size:calc(100vw * 12 / 1920);font-weight:600;padding-top:calc(100vw * 4/ 1920);padding-bottom:calc(100vw * 4/ 1920);">{{item.name}}</el-link></div>
        <div style="padding-left:calc(100vw * 5/ 1920);padding-right:calc(100vw * 5/ 1920);line-height:calc(100vw * 13/ 1920);">
        <span style="font-size: calc(100vw * 4/ 640);color:#757474;">{{item.desc | longStr}}</span></div>
        </el-main>
        </el-container>
    </el-card>
    </el-tooltip>
    </el-col>
    </el-row>
    </div>
    </div>

    <div class="data-box-small" v-if="windowWidth<800">

        <el-collapse accordion>

        <el-collapse-item v-for="(items,indexs) in urlsData" :key="indexs">
            <template slot="title"><i class="el-icon-mobile-phone"> {{items.name}}</i></template>
            <el-row>
            <el-col :span="24" v-for="(item,index) in items.data" :key="index" class="card-box">
            <el-tooltip effect="dark" :content=item.desc placement="bottom" :visible-arrow="false">
            <el-card :shadow="shadowTime" style="cursor:pointer;" :body-style="{ padding: '5px' }" body- @click.native="goto(item.thepath)">
                <el-container style="height:50px;">
                <el-aside width="120" style="margin:0 !important;padding:5px;">
                <img :src= item.logopath class="image" style="height:30px;width:30px;">
                </el-aside>
                <el-main style="text-align: left !important;padding:0 !important;margin:0 !important;">
                <div style="padding-bottom:3px;"><el-link target="_blank" type="primary" style="color:#4778ab !important;font-size:12px;font-weight:600;">{{item.name}}</el-link></div>
                <div style="padding-left:2px;padding-right:5px;  "><span style="font-size:4px;color:#757474;">{{item.desc | longStr2}}</span></div>
                </el-main>
                </el-container>
            </el-card>
            </el-tooltip>
            </el-col>
            </el-row>
        </el-collapse-item>

        </el-collapse>

    </div>


    <div class="minitext">
      <div style="width:300px;margin:0 auto; padding:15px 0;">
      <a 
        href="http://beian.miit.gov.cn/" 
        target="_blank" 
        style="color:#fff;display:inline-block;text-decoration:none;height:calc(100vw * 20/ 1920);line-height:calc(100vw * 20/ 1920);">{{beian_code}}
      </a>
      <div style="height:15px" v-if="windowWidth<800"></div>
      <a target="_blank"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44130202001091"
        style="display:inline-block;text-decoration:none;height:calc(100vw * 20/ 1920);line-height:calc(100vw * 20/ 1920);display:flex;justify-content:center;align-items:center;">
        <img src='https://orang.hundun.ink/static/img/beian.ef881569.png' style="float:left;display:inline-block;"/>
        <p 
            style="float:left;display:inline-block; color:#fff;">
            粤公网安备 44130202001091号
        </p>
      </a>
      </div>
    </div>

  </div>
</template>

<script>

import { getUrls, } from '@/api/urls'
import { mapGetters, mapMutations } from 'vuex'
export default {
  data () {
    return {
        beian_code: '粤ICP备2021046221号',
        backurl: 'https://www.eatqionline.top:8008',
        msg: 'Welcome to PC',
        windowWidth: document.documentElement.clientWidth,  //实时屏幕宽度
        windowHeight: document.documentElement.clientHeight,   //实时屏幕高度
        restaurants: [],
        search: '',
        types: [
            {
                value:'个人知库1',
                key:'eatqi'
            },{
                value:'个人知库2',
                key:'eatqi2'
            },
            {
                value:'CSDN',
                key:'csdn'
            },
            {
                value:'百度',
                key:'baidu'
            },
            {
                value:'百度开发者',
                key:'kaifa'
            },
            {
                value:'Bing',
                key:'bing'
            },
            {
                value:'Google',
                key:'google'
            },
        ],
        urlsData: [],
        projectData: [],
        bookData:[],
        toolData:[],
        communityData:[],
        shadowTime: 'hover',
        showFixedSearch:false,
    }
  },
  watch: {
    windowHeight (val) {
    let that = this;
    console.log("实时屏幕高度：",val, that.windowHeight );
    },
    windowWidth (val) {
    let that = this;
    console.log("实时屏幕宽度：",val, that.windowHeight );
    }
  },
  filters:{
      longStr(value){
        if (!value) return "";
        if (value.length > 30) {
        return value.slice(0, 29) + "…";
        }
        return value;
      },
      longStr2(value){
        if (!value) return "";
        if (value.length > 20) {
        return value.slice(0, 19) + "…";
        }
        return value;
      }
  },
  mounted() {
    var that = this;
    // <!--把window.onresize事件挂在到mounted函数上-->
    window.onresize = () => {
    return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        window.fullWidth = document.documentElement.clientWidth;
        that.windowHeight = window.fullHeight;  // 高
        that.windowWidth = window.fullWidth; // 宽
    })()
    };
     // 监听页面滚动事件
    window.addEventListener("scroll", this.showSearch)
  },
  created () {
      this.getData()
      if ( this.restaurantsData.length > 0 ){
        this.restaurants = this.restaurantsData
      }
  },
  methods: {
      showSearch() {
        // 获取当前滚动条向下滚动的距离
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        // 当页面滚动到高度300px处时，显示搜索框
        if (scrollTop > 300) {
            this.showFixedSearch = true;
        } else {
            this.showFixedSearch = false;
        }
      },
      getData(){
        getUrls('all').then((res) => {
            console.log('页面获取到数据',res)
            if (res.code == 200) {
                this.urlsData = res.data.url
            }
        })
        },
      getSearch(){
        var inData = false
        for (let index = 0; index < this.restaurants.length; index++) {
            if (this.restaurants[index]["value"] == this.search) {
                inData = true
            }
        }
        if (!inData) {
            this.restaurants.push({ "value": this.search, "address": "" })
        }
        // 变更缓存
        this.restaurantsData = this.restaurants

        if (this.type == 'eatqi') {
            window.open('http://106.13.1.2/search/?kw='+ this.search + '&type=doc', '_blank')
        } else if (this.type == 'eatqi2') {
            window.open('https://doc.a8a8a8.top/search/?kw='+ this.search + '&type=doc', '_blank')
        } else if (this.type == 'csdn') {
            window.open('https://so.csdn.net/so/search?q='+ this.search + '&t=blog', '_blank')
        } else if (this.type == 'baidu') {
            window.open('https://www.baidu.com/s?wd='+ this.search, '_blank')
        } else if (this.type == 'kaifa') {
            window.open('https://kaifa.baidu.com/searchPage?wd='+ this.search , '_blank')
        } else if (this.type == 'bing') {
            window.open('https://cn.bing.com/search?q='+ this.search, '_blank')
        } else if (this.type == 'google') {
            window.open('https://www.google.com/search?q='+ this.search, '_blank')
        }
      },
      onDelClick(value){
        for (let index = 0; index < this.restaurants.length; index++) {
            if (this.restaurants[index]["value"] == value) {
                this.restaurants.splice(index)
            }
        }
        // 变更缓存
        this.restaurantsData = this.restaurants

      },
      querySearch(queryString, cb) {
        var restaurants = this.restaurants;
        var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        // 调用 callback 返回建议列表的数据
        cb(results);
      },
      createFilter(queryString) {
        return (restaurant) => {
          return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },
      handleSelect(item) {
        console.log(item);
      },
      handleIconClick(ev) {
        console.log(ev);
      },
      changeTag(tag){
        this.type = tag
      },
      goto (path) {
        window.open(path, '_blank')
      },
      ...mapMutations('storeData', ['setRestaurantsData', 'setType']),
    
  },
  computed: {
    restaurantsData: {
      get () {
        return this.$store.state.storeData.restaurantsData
      },
      set (value) {
        this.setRestaurantsData(value)
      }
    },
    type: {
      get () {
        return this.$store.state.storeData.type
      },
      set (value) {
        this.setType(value)
      }
    },
    ...mapGetters('storeData', [
      'storeDataFrom'
    ])
  },
}
</script>

<style lang="scss" scoped>
.color-box {
    height: 300px;
    background-color: aqua;
    background-color: steelblue;
    background: url(../../assets/imgs/background.jpeg);
    background-size:cover;
    background-position:center;
    -webkit-font-smoothing: antialiased;// 这个属性可以使页面上的字体抗锯齿,使用后字体看起来会更清晰舒服
    font-weight: 300;
}
.search-box {
    margin: 0 auto;
    text-align: center;
    transform: translateY(-50%);
    width: 35%;
}
.search-box-small {
    margin: 0 auto;
    text-align: center;
    transform: translateY(-50%);
    width: 80%;
}
.search-box-fixed {
    margin: 0 auto;
    text-align: center;
    transform: translateY(10%);
    width: 35%;
}
.search-box-small-fixed {
    margin: 0 auto;
    text-align: center;
    transform: translateY(10%);
    width: 80%;
}

.tag-box {
    margin: 0 auto;
    text-align: center;
}
.data-box {
    margin: 0 auto;
    text-align: center;
    padding-top:20px;
    padding-bottom: 20px;
    padding-left: 10%;
    padding-right: 10%;
    min-height: 38vh;
}
.data-box-small {
    margin: 0 auto;
    text-align: center;
    padding-top:20px;
    padding-bottom: 20px;
    padding-left: 6%;
    padding-right: 6%;
    min-height: 38vh;
}
.card-box {
    padding-top:1%;
    padding-bottom: 1%;
    padding-left: 3%;
    padding-right: 3%;
}
.minitext {
    background-color: #00aeff;
    text-align: center;
}
.fixedSearch {
    position: fixed;
    width: 100%;
    z-index: 999;
    background-color: #00aeff;
    height: 50px;
    overflow: hidden;
}
/deep/.el-autocomplete {
    display: block;
}
/deep/.el-input-group--append .el-input__inner, .el-input-group__prepend {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}
/deep/.el-input-group__append{
    border-top-right-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
}
/deep/.el-input-group--prepend .el-input__inner, .el-input-group__append {
    border-top-right-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
}

/deep/.el-input-group__append button.el-button {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}
</style>