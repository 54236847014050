import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const Index = () => import(/* webpackChunkName: "index" */ '../views/HomePage/index.vue')
const routes = [
  { path: '/', name: 'Index', component: Index },
  {
    path: '/index',
    name: 'index',
    component: Index,
    meta: {
      title: '阿巴阿巴站点'
    }
  },
]

const router = new VueRouter({
  mode: "history",
  routes
})

export default router
