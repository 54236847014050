const state = () => ({
    restaurantsData: [],
    restaurants: [],
    type: 'eatqi'
  })
  
  // 获取vuex状态
  const getters = {
    storeDataFrom: state => {
      return {
        restaurantsData: state.restaurantsData,
        restaurants: state.restaurants,
        type: state.type
      }
    }
  }
  
  const mutations = {
    setRestaurantsData (state, value) {
      state.restaurantsData = value
    },
    setRestaurants (state, value) {
      state.restaurants = value
    },
    setType (state, value) {
      state.type = value
    },
  }
  
  const actions = {
  }
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
  }
  